import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ContactElement from "./Components/ContactElement";
import ParallaxElement from "./Components/ParallaxElement";
import Contacts from "./Components/Contacts";
import Slider from "./Components/Slider";

import ocean from './ocean.png';
import cardCheck from './Card_check.jpg';
import cardClock from './Card_clock.jpg';
import cardHome from './Card_home.jpg';
import cardTools from './Card_tools.jpg';
import * as Icon from 'react-bootstrap-icons';

const _cardText = [["Монтаж", "Мы готовы выполнить работы любой сложности, от установки простых вытяжных систем до монтажа сложных инженерных решений.", cardTools ],
["Работа любой сложности", "Мы работаем с коммерческими, промышленными и жилыми объектами, обеспечивая качественное и эффективное функционирование системы вентиляции.", cardHome],
["Изготавливаем в кратчайшие сроки", "Изготовление системы вентиляции в кратчайшие сроки, без ущерба для качества работ.", cardClock],
["Гарантия", "Мы используем только качественные комплектующие и оборудование, чтобы обеспечить долговечность и надежность системы вентиляции. ", cardCheck]];
//------------------------------------------------------------
// <Icon.Instagram style={{ margin: '20px'}} color="royalblue" size={50} className="align-top text-center align-items-center" />
                
export const Home = () => (
    <>
        <Slider />
        
        <Container style={{ paddingTop: '6rem', paddingBottom: '5rem' }}>
            <Row lg={4} xs={1} md={2} className="g-4" style={{ marginBottom: "30px" }}>
                {_cardText.map((_text, idx) => (
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={_text[2]} />
                            <Card.Body>
                                <Card.Title>{_text[0]}</Card.Title>
                                <Card.Text>
                                    {_text[1]}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>

        {/* <Container fluid style={{ backgroundColor: "#6b5b95", padding: '4rem' }}>
            <Row mb={5} className="align-items-center" style={{ padding: '30px' }}>
                <Col md={5}>
                    <h2 className="text-center">О компании</h2>
                    
                </Col>
                <Col md={7} >
                    <img src={ocean} height={300} className="img-fluid" />
                </Col>
            </Row>
        </Container> */}

        <ParallaxElement />

        <Contacts />
        
        <ContactElement />
    </>
)

// ( mr = me )
// div className = 'my-5'
// Row className = 'mb-4'
// col md = '3' xs='6'
// , marginBottom: '30px'

