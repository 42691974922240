import React, { Component, useState, useRef } from "react";
import { Container, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";

import emailjs from '@emailjs/browser';

// import ocean from '../ocean.png'
// import styled from 'styled-components';

// const Styles = styled.div`
//     .plax {
//         background: url(${ocean}) no-repeat fixed bottom;
//         background-size: cover;
//         color:#efefef;
//         height: 400px;
//         position: relative;
//         z-index: -2;
//     }
//     .overlay {
//         background-color: #000;
//         opacity: 0.7;
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom 0;
//         right: 0;
//         z-index: -1;
//     }
// `

// const ContactElement = () => (
//     <Styles>
//         <Container fluid className="plax">
//             <div className="overlay"></div>
//             <Container>
//                 <h1>Web Developer Blog</h1>
//                 <p> random text </p>
//             </Container>
//         </Container>
//     </Styles>
// )

// export default ContactElement;

//---------------main-------------------
// const ContactElement = () => (
//     <>
//         <Container style={{width: '500px'}}>
//             <h1 className="text-center">Контакты</h1>
//             <Form>
//                 <Form.Group controlId="formBasicEmail">
//                     <Form.Label>Email</Form.Label>
//                     <Form.Control type="email" placeholder="E-mail" />
//                     <Form.Text>
//                         blablabla text
//                     </Form.Text>
//                 </Form.Group>

//                 <Form.Group controlId="formBasicPassword">
//                     <Form.Label>Email</Form.Label>
//                     <Form.Control as="textarea" rows="3" />
//                 </Form.Group>

//                 <Form.Group controlId="formBasicCheckbox">
//                     <Form.Check type="checkbox" label="Check me out" />
//                 </Form.Group>
//                 <Button variant="primary" type="submit">Submit</Button>
//             </Form>
//         </Container>
//     </>
// )

// export default ContactElement;
//----------------------------------
// Для отправки формы в React.js можно использовать обычный HTML-элемент <form> и добавить к нему обработчик события onSubmit.
// Вот пример кода:

// import React, { useState } from 'react';

function NameForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    // здесь можно добавить логику отправки данных на сервер
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Container style={{ width: '40vmax', paddingTop: '30px', paddingBottom: '30px' }} id="_call" >
      <h2 style={{ paddingTop: '30px', paddingBottom: '30px' }} className="text-center">Обратная связь</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Имя</Form.Label>
          <Form.Control type="text" name="name" placeholder="Введите ваше имя" value={formData.name} onChange={handleChange} />
        </Form.Group>

        <Form.Group style={{ paddingTop: '30px', paddingBottom: '30px' }} controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" placeholder="Введите Email" value={formData.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formBasicText">
          <Form.Label>Сообщение</Form.Label>
          <Form.Control as="textarea" name="message" rows="3" value={formData.message} onChange={handleChange} />
        </Form.Group>
        <Button style={{ marginTop: '30px', marginBottom: '30px' }} variant="primary" type="submit">Отправить</Button>
      </Form>
    </Container>
  );
}

// export default NameForm;

// В этом примере мы создаем компонент Form, который содержит форму с тремя полями: name, email и message. Мы используем хук useState для отслеживания состояния формы, и добавляем обработчики событий onSubmit и onChange.
// Когда пользователь отправляет форму, обработчик handleSubmit вызывается, и мы можем добавить логику отправки данных на сервер. В этом примере мы просто выводим данные формы в консоль.
// Обработчик handleChange вызывается при изменении любого из полей формы, и мы обновляем состояние формы с помощью хука useState.
// Наконец, мы добавляем кнопку отправки формы с атрибутом type="submit", который говорит браузеру отправить форму, когда пользователь нажимает кнопку.

//-------------------------

// <form onSubmit={handleSubmit}>
// <label>
//   Name:
//   <input type="text" name="name" value={formData.name} onChange={handleChange} />
// </label>
// <label>
//   Email:
//   <input type="email" name="email" value={formData.email} onChange={handleChange} />
// </label>
// <label>
//   Message:
//   <textarea name="message" value={formData.message} onChange={handleChange} />
// </label>
// {/* <button type="submit">Send</button> */}
// <Button variant="primary" type="submit">Submit</Button>
// </form>

//=====================================================

// function MailForm() {
//   const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: "onBlur" });

//   const onSubmit = (data) => {
//     alert(JSON.stringify(data));
//     reset();
//   }
//   // const onSubmit = data => console.log(data);


//   return (
//     <div className="MailForm">
//       <h1>React Hook Form</h1>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <label>
//           First Name:
//           <input
//             {...register('firstName', {
//               required: "Поле обязательно к заполнению",
//               minLength: {
//                 value: 3,
//                 message: 'Минимум 3 символа'
//               },
//               pattern: /[A-Za-z]{3}/
//             })} />
//         </label>
//         <div style={{ height: 40 }}>
//           {errors?.firstName && <p>{errors?.firstName?.message || "Error!!!"}</p>}
//         </div>

//         <label>
//           Last Name:
//           <input
//             {...register('lastName', {
//               required: "Поле обязательно к заполнению",
//               minLength: {
//                 value: 3,
//                 message: 'Минимум 3 символа'
//               },
//               pattern: /^(0|[A-Za-z]\d*)(\.\d+)?$/
//               // valueAsNumber: false, /^(0|[1-9]\d*)(\.\d+)?$/
//             })} />
//         </label>
//         <div style={{ height: 40 }}>
//           {errors?.lastName && <p>{errors?.lastName?.message || "Error!!!"}</p>}
//         </div>

//         <input type="submit" disabled={!isValid} />
//       </form>
//     </div>
//   )
// }

// export default MailForm;
//====================================================================
//====================================================================
//   emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
//  e.target.reset();
//--------------------------------------------------------------------
// const form = useRef();

// const sendEmail = (e) => {
//   e.preventDefault();

//   emailjs.sendForm('service_w5exkfj', 'template_z7vbzxo', form.current, 'BJ8nmy_Cr8sSA86vk')
//     .then((result) => {
//         console.log(result.text);
//     }, (error) => {
//         console.log(error.text);
//     });
// };
//--------------------------------------------------------------------
// return (
//   <form ref={form} onSubmit={sendEmail}>
//     <label>Name</label>
//     <input type="text" name="user_name" />
//     <label>Email</label>
//     <input type="email" name="user_email" />
//     <label>Message</label>
//     <textarea name="message" />
//     <input type="submit" value="Send" />
//   </form>
// );
//--------------------------------------------------------------------
function EmailForm() {
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: "onBlur" });

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    // console.log("message send");
    alert("message send");
    console.log(JSON.stringify(data));
    // // Validate the form data
    // const { name, email, message } = form.data;
    // if (!name || !email || !message) {
    //   return;
    // }

    // Send the email
    // emailjs.send('service_w5exkfj', 'template_z7vbzxo', {
    //   Name,
    //   Email,
    //   message,
    // }, 'BJ8nmy_Cr8sSA86vk');

    emailjs.send('service_w5exkfj', 'template_z7vbzxo', data, 'BJ8nmy_Cr8sSA86vk');
    // Send the email End
    reset();
  }



  return (
    <Container style={{ width: '40vmax', paddingTop: '30px', paddingBottom: '30px' }} id="_call" >
      <h2 style={{ paddingTop: '30px', paddingBottom: '30px' }} className="text-center">Отправить сообщение</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Имя</Form.Label>
          <Form.Control type="text" placeholder="Введите ваше имя"
            {...register('Name', {
              required: "Поле обязательно к заполнению",
              minLength: {
                value: 3,
                message: 'Минимум 3 символа'
              },
              pattern: /^(\(?\+?[a-zA-Z]+[ЁёА-я]*\)?)?[a-zA-ZЁёА-я_\- \(\)]*$/
            })} />
          <Form.Text muted>
            {errors?.Name && <p>{errors?.Name?.message || "Error!!!"}</p>}
          </Form.Text>
        </Form.Group>

        <Form.Group style={{ paddingTop: '30px', paddingBottom: '30px' }} controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" placeholder="Введите ваш Email"
            {...register('Email', {
              required: "Поле обязательно к заполнению",
              minLength: {
                value: 1,
                message: 'Минимум 1 символ'
              },
              pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            })} />
          <Form.Text muted>
            {errors?.Email && <p>{errors?.Email?.message || "Введите Email"}</p>}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicText">
          <Form.Label>Сообщение</Form.Label>
          <Form.Control as="textarea" rows="3"
            {...register('message', {
              required: "Поле обязательно к заполнению",
              minLength: {
                value: 1,
                message: 'Минимум 1 символ'
              }
            })} />
          <Form.Text muted>
            {errors?.message && <p>{errors?.message?.message || "Введите Сообщение"}</p>}
          </Form.Text>
        </Form.Group>
        <Button style={{ marginTop: '30px', marginBottom: '30px' }} variant="primary" type="submit" disabled={!isValid}>Отправить</Button>
      </Form>
    </Container>
  );
}

export default EmailForm;

// name="name" value={formData.name} onChange={handleChange}
// type="email" name="email" placeholder="Введите Email" value={formData.email} onChange={handleChange}
// as="textarea" name="message" rows="3" value={formData.message} onChange={handleChange} 
{/* <div style={{ height: 40 }}>
  {errors?.messageName && <p>{errors?.messageName?.message || "Введите Сообщение"}</p>}
</div> */}
//  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//  ^(.*)[<["(]((?:[\w\d]+[.%-]?)+@(?:[[:alnum:]-]+\.)+[a-z]{2,})[)"\]>]$
//  ^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$
//  /^\S+@\S+$/
//  /[A-Za-z]{3}/
//  ^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$
//  ^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)
//  ^([a-zA-Z]{3,}'?)
//  ^(\(?\+?[a-zA-Z]*\)?)?[a-zA-Z_\- \(\)]*$
//  ^(\(?\+?[a-zA-Z]+[ЁёА-я]*\)?)?[a-zA-Z_\- \(\)]*$