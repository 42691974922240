// import React from "react";
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ocean from './ocean.png';
//import TestElement from "./Components/TestElement";
// import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { MapContainer, TileLayer, useMap, useMapEvent, Marker, Popup, Rectangle } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import L from "leaflet";

import styled from 'styled-components';

import "./About.css";

const _re1 = [["food", "basic"], ["heavy", "money"], ["car", "mine"], ["kitty", "dog"]];

// export const About = () => (
//     <>
//         {/* <TestElement/> */}
//         <Container>
//             <h1>Page About</h1>
//             <div className="my-5">
//                 <Row className="mb-4">
//                     {_re1.map(item => (
//                         <Col md='3' xs='6'>
//                             <Card style={{ width: '%' }}>
//                                 <Card.Img variant="top" src={ocean} className="img-fluid" />
//                                 <Card.Body>
//                                     <Card.Title>Card Title {item[0]}</Card.Title>
//                                     <Card.Text>
//                                         Some quick example text to build on the card title and make up the
//                                         bulk of the card's content. {item[1]}
//                                     </Card.Text>
//                                     <Button variant="primary">Go somewhere</Button>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     ))}
//                 </Row>
//             </div>
//         </Container>
//         <Container>
//             <Row lg={4} xs={1} md={2} className="g-4" style={{marginBottom: "30px"}}>
//                 {Array.from({ length: 4 }).map((_text1, idx) => (
//                     <Col>
//                         <Card>
//                             <Card.Img variant="top" src={ocean} />
//                             <Card.Body>
//                                 <Card.Title>Card title</Card.Title>
//                                 <Card.Text>
//                                     This is a longer card with supporting text below as a natural
//                                     lead-in to additional content. This content is a little bit
//                                     longer.
//                                 </Card.Text>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 ))}
//             </Row>
//         </Container>

//         <Container fluid style={{backgroundColor: "brown", paddingTop: '2rem', paddingBottom: '2rem'}}>
//             <Row className="justify-content-center">
//                 <Col>
//                     <Card className="text-center bg-dark text-white" style={{width: '18rem', marginBottom: '30px'}}>
//                         <Card.Img variant="top" src={ocean} />
//                         <Card.Body>
//                             <Card.Title>Blog text 1</Card.Title>
//                             <Card.Text>
//                                 Some text to image
//                             </Card.Text>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col>
//                     <Card className="text-center" style={{width: '18rem', marginBottom: '30px'}}>
//                         <Card.Img variant="top" src={ocean} />
//                         <Card.Body>
//                             <Card.Title>Blog text 2</Card.Title>
//                             <Card.Text>
//                                 Some text to image
//                             </Card.Text>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col>
//                     <Card style={{width: '18rem', marginBottom: '30px'}}>
//                         <Card.Img variant="top" src={ocean} />
//                         <Card.Body className="text-center">
//                             <Card.Title>Blog text 3</Card.Title>
//                             <Card.Text>
//                                 Some text to image
//                             </Card.Text>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col>
//                     <Card style={{width: '18rem', marginBottom: '30px'}}>
//                         <Card.Img variant="top" src={ocean} />
//                         <Card.Body className="text-center">
//                             <Card.Title>Blog text 4</Card.Title>
//                             <Card.Text>
//                                 Some text to image
//                             </Card.Text>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     </>
// )

// function About() {
//     return(
//         <Container>
//             <h1>Page About</h1>
//         </Container>
//     )
// }
// export default About;

// Array.from({ length: 4 }).map((_text1, idx) => (
//=================================================================================
const Styles = styled.div`
    .parallax {
        /* The image used */
        background-image: url(${ocean});
      
        /* Set a specific height */
        min-height: 500px;
      
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .plax {
        background: url(${ocean}) no-repeat fixed bottom;
        background-size: cover;
        color:#efefef;
        height: 500px;
        position: relative;
        z-index: -2;
    }
    .leaflet-container {
        height: 600px;
        width: 100%;
    }
`

//---------------------------------------------------------------------------------
function MapPlaceholder() {
    return (
        <p>
            Map of London.{' '}
            <noscript>You need to enable JavaScript to see this map.</noscript>
        </p>
    )
}
// <MapContainer className="overlay" style={{ width: '100%', height: '500px' }}
// className="leaflet-container"
function MapWithPlaceholder() {
    return (
        <Styles className="leaflet-container">
            <MapContainer style={{ width: '100%', height: '500px' }}
                center={[52.287131, 76.986630]}
                zoom={16}
                scrollWheelZoom={false}
                placeholder={<MapPlaceholder />}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </Styles>
    )
}

//   render(<MapWithPlaceholder />)
//-------------------------------------------------------------------------
// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

const BOUNDS_STYLE = { weight: 1 }

function MinimapBounds({ parentMap, zoom }) {
    const minimap = useMap()

    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback(
        (e) => {
            parentMap.setView(e.latlng, parentMap.getZoom())
        },
        [parentMap],
    )
    useMapEvent('click', onClick)

    // Keep track of bounds in state to trigger renders
    const [bounds, setBounds] = useState(parentMap.getBounds())
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        // Update the minimap's view to match the parent map's center and zoom
        minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])

    // Listen to events on the parent map
    const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
    useEventHandlers({ instance: parentMap }, handlers)

    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

function MinimapControl({ position, zoom }) {
    const parentMap = useMap()
    const mapZoom = zoom || 0

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(
        () => (
            <MapContainer
                style={{ height: 80, width: 80 }}
                center={parentMap.getCenter()}
                zoom={mapZoom}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                attributionControl={false}
                zoomControl={false}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
            </MapContainer>
        ),
        [],
    )

    const positionClass =
        (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{minimap}</div>
        </div>
    )
}

function ReactControlExample() {
    return (
        <MapContainer center={[51.505, -0.09]} zoom={6} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MinimapControl position="topright" />
        </MapContainer>
    )
}

// render(<ReactControlExample />)

//-------------------------------------------------------------------------


//-------------------------------------------------------------------------
// export const About = () => (
//     <>
//         <MapContainer style={{ width: '100%', height: '500px' }} center={[52.287131, 76.986630]} zoom={16} scrollWheelZoom={false}>
//             <TileLayer
//                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             />
//             <Marker position={[52.287131, 76.986630]}>
//                 <Popup>
//                     A pretty CSS3 popup. <br /> Easily customizable.
//                 </Popup>
//             </Marker>
//         </MapContainer>
//     </>
// )
//-------------------------------------------------------------------------
// class Contacts extends React.Component {
//     render() {
//       return <h1>Hello, {this.props.name}</h1>;
//     }
// }
//-------------------------------------------------------------------------
// export const About = () => (

//     <>
//         <MapWithPlaceholder />
//     </>
// )

// <MapWithPlaceholder />
// <ReactControlExample />
//------------------------------------------------------

export function About() {
    const position = [52.28670,76.98581]

    return (
        <div className="leaflet-container">
            <MapContainer attributionControl={false} center={position} zoom={16} scrollWheelZoom={false} zoomControl={false} doubleClickZoom={false} dragging={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        ПК "Промммонтаж-ПВ" <br /> Компания.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}

let DefaultIcon = L.icon({
    iconUrl: "/marker-icon-2x.png",
    iconSize: [25,41]
})
L.Marker.prototype.options.icon = DefaultIcon;
// @import url("~leaflet/dist/leaflet.css");
// node_modules\leaflet\dist\images\marker-icon.png
// marker-icon-2x
// ./ocean.png
// https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png

// var greenIcon = L.icon({
//     iconUrl: 'leaf-green.png',
//     shadowUrl: 'leaf-shadow.png',

//     iconSize:     [38, 95], // size of the icon
//     shadowSize:   [50, 64], // size of the shadow
//     iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
//     shadowAnchor: [4, 62],  // the same for the shadow
//     popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
// });

// export default About;