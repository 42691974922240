import React from "react";
import { Carousel } from "react-bootstrap";
import ocean from '../ocean.png';
import pic1 from '../Site1.jpg';
import pic2 from '../Site2.jpg';

export default function Slider(){
    var visota = {'height':'27vmax'} //{'height':'40%'} 600px 40% 40rem 27vmax 1vw 1vh 1vmin clamp(200px, 50%, 1000px); // fade
   
    return (
        <Carousel variant="dark" interval={10000}>   
            <Carousel.Item style={visota}>
                <img
                    className="d-block w-100"
                    src={pic1}
                    alt="First slide"
                />
                {/* <Carousel.Caption>
                    <h3>Web Developer Blog 1</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item style={visota}>
                <img
                    className="d-block w-100"
                    src={pic2}
                    alt="First slide"
                />
                {/* <Carousel.Caption>
                    <h3>Web Developer Blog 2</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption> */}
            </Carousel.Item>

        </Carousel>
    )
}


// <Carousel.Item style={visota}>
// <img
//     className="d-block w-100"
//     src={ocean}
//     alt="First slide"
// />
// <Carousel.Caption>
//     <h3>Web Developer Blog 3</h3>
//     <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
// </Carousel.Caption>
// </Carousel.Item>
