import React, { Component, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ocean from '../ocean.png';
// import ReactDOM from 'react-dom';
// import { load } from '@2gis/mapgl';
// import DG from '2gis-maps';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
//------------------------------------------------
// const MapWrapper = React.memo(
//     () => {
//         return <div id="map-container" style={{ width: '100%', height: '100%' }}></div>;
//     },
//     () => true,
// );

// const Map = () => {
//     useEffect(() => {
//         let map;
//         load().then((mapglAPI) => {
//             map = new mapglAPI.Map('map-container', {
//                 center: [55.31878, 25.23584],
//                 zoom: 13,
//                 key: 'Your API access key',
//             });
//         });

//         // Удаляем карту при размонтировании компонента
//         return () => map && map.destroy();
//     }, []);

//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <MapWrapper />
//         </div>
//     );
// };

{/* <div style={{ width: '100%', height: 400 }}>
        <Map />
    </div> */}
//------------------------------------------------
// async function start() {
//     const mapglAPI = await load();

//     // container — id of the div element in your html
//     const map = new mapglAPI.Map('container', {
//         center: [55.31878, 25.23584],
//         zoom: 13,
//         key: 'Your API access key',
//     });

//     const marker = new mapglAPI.Marker(map, {
//         coordinates: [55.31878, 25.23584],
//     });
// }

//------------------------------------------------
// const Map = () => {
//     load().then((mapglAPI) => {
//         const map = new mapglAPI.Map('container', {
//             center: [55.31878, 25.23584],
//             zoom: 13,
//             key: 'Your API access key',
//         });
//     });
// }

//------------------------------------------------


// const position = [51.505, -0.09]

// render(
//   <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
//     <TileLayer
//       attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//     />
//     <Marker position={position}>
//       <Popup>
//         A pretty CSS3 popup. <br /> Easily customizable.
//       </Popup>
//     </Marker>
//   </MapContainer>,
// )
//------------------------------------------------
// class Contacts extends React.Component {
//     render() {
//       return <h1>Hello, {this.props.name}</h1>;
//     }
// }
//------------------------------------------------
//<script type="text/javascript" charset="utf-8" async src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A7463cfd33e04dabeb68f682293476bbc72f9a1ddc203b3bd68fd69af0fdcf649&amp;width=500&amp;height=450&amp;lang=ru_RU&amp;scroll=true"></script>

//------------------------------------------------
function Contacts() {
    const position = [52.28670,76.98581]
    return (
        // <div className="Contacts">
        //     {/* <Map /> */}
        // </div>
        // paddingTop: '50px', paddingBottom: '50px' 
        <>


            <Container fluid style={{ backgroundColor: '#347C98', color: '#fff' }} id="_contacts">
                <Row mb={5} className="align-items-center" style={{ padding: '6vmin' }}>
                    {/* <Map /> */}

                    <Col md={7} >
                        {/* <img src={ocean} height={400} className="img-fluid" /> */}
                        <MapContainer style={{ width: '100%', height: '500px' }} attributionControl={false} scrollWheelZoom={false} zoomControl={true} doubleClickZoom={false} dragging={false} center={position} zoom={16}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position}>
                                <Popup>
                                    ПК "Промммонтаж-ПВ" <br /> Компания.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Col>
                    <Col md={5} className="text-center" >
                        <h2 className="text-center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>Контакты</h2>
                        <p><h4>Адрес:</h4></p>
                        <p>
                            Павлодар, Улица Малайсары батыра, 9
                        </p>
                        <p><h4>Телефон:</h4></p>
                        <p>
                            <a style={{ color: "white" }} href="tel:+77785704531">+77785704531</a><br />
                            <a style={{ color: "white" }} href="tel:+77013681142">+77013681142</a><br />
                            <a style={{ color: "white" }} href="tel:+77013671935">+77013671935</a>
                        </p>
                        <p><h4>Email:</h4></p>
                        <p>

                            <a style={{ color: "white" }} href="mailto:pk-prommontazh@mail.ru">pk-prommontazh@mail.ru</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

// import mapIcon from './icon.png'

// const Map = () => {
//     useEffect(() => {
//         let map
//         map = DG.map("map-container", {
//             center: [55.31, 25.23],
//             zoom: 5
//         })

//         const icon = DG.icon({
//             iconUrl: mapIcon,
//             iconSize: [30,30]
//         })

//         DG.marker([55.31, 25.23], { icon: icon}).addTo(map).bindPopup('Open!')
//     }, [])

//     return (
//         <div id="map-container" className="align-items-center text-center" style={{ width: '500px', height: '500px' }}>

//         </div>
//     )
// }



export default Contacts;
//-------------------main-----------------------------

// const Contacts = () => (
//     <>

//         <Container fluid style={{ backgroundColor: '#347C98', color: '#fff' }} id="_contacts">
//             <Row mb={5} className="align-items-center" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
//                 {/* <Map /> */}
//                 <Col md={7} >
//                     {/* <img src={ocean} height={400} className="img-fluid" /> */}

//                 </Col>
//                 <Col md={5} className="text-center" >
//                     <h2 className="text-center" style={{ paddingBottom: '20px' }}>Контакты</h2>
//                     <p><h4>Адрес:</h4></p>
//                     <p>
//                         Павлодар, Улица Малайсары батыра, 9
//                     </p>
//                     <p><h4>Телефон:</h4></p>
//                     <p>
//                         <a style={{ color: "white" }} href="tel:+77785704531">+77785704531</a><br />
//                         <a style={{ color: "white" }} href="tel:+77013681142">+77013681142</a><br />
//                         <a style={{ color: "white" }} href="tel:+77013671935">+77013671935</a>
//                     </p>
//                     <p><h4>Email:</h4></p>
//                     <p>

//                         <a style={{ color: "white" }} href="mailto:pk-prommontazh@mail.ru">pk-prommontazh@mail.ru</a>
//                     </p>
//                 </Col>
//             </Row>
//         </Container>
//     </>
// )

// export default Contacts;
//------------------------------------------------


// export default class Contacts extends Component {
//     render() {
//         return (
//             <Container>
//                 <h1>Contact us</h1>
//             </Container>
//         )
//     }
// }
// Email: &nbsp;

{/* <ul className="content-list">
<li className="content-list__item">
    <h2 className="title-2">Адрес</h2>
    <p>Казахстан, Павлодар, ул. Циолковского 31</p>
</li>
<li className="content-list__item">
    <h2 className="title-2">Телефон</h2>
    <p>
        <a href="tel:+77182602037">+7 718-26-02-037</a>
    </p>
</li>
<li className="content-list__item">
    <h2 className="title-2">Email</h2>
    <p>
        <a href="mailto:pk-prommontazh@mail.ru">
            pk-prommontazh@mail.ru
        </a>
    </p>
</li>
</ul> */}


