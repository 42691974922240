import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Navibar from './Components/Navibar';
import Footer from './Components/Footer';

import {
  BrowserRouter as Router,
  // Switch,
  Routes,
  Route
} from "react-router-dom"

// import ReactDOM from "react-dom/client";
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";

import { Home } from './Home'
import { About } from './About'
// import { Contacts } from './Contacts'

function App() {
  return (
    <>
      <Router>
        <Navibar />
        {/* <Switch>
        <Route exact path="/" element={<Home/>} />
        <Route path="/about" component={About} />
        <Route path="/contacts" component={Contacts} />
        </Switch> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/about' element={<About/>} />
          {/* <Route path="contacts/*" element={<Contacts />} /> */}
        </Routes>
        {/* <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contacts' element={<Contacts/>}/>
        </Routes> */}
      </Router>
      <Footer/>
    </>
  );
}

export default App;
