import React, { Component } from "react";
import { Container, Row, Col, } from "react-bootstrap";
import ocean from '../Pipes.jpg';
import styled from 'styled-components';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { Parallax, Background } from 'react-parallax';

const Styles = styled.div`
    .parallax {
        /* The image used */
        background-image: url(${ocean});
      
        /* Set a specific height */
        min-height: 500px;
      
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .plax {
        background: url(${ocean}) no-repeat fixed bottom;
        background-size: cover;
        color:#efefef;
        height: 500px;
        position: relative;
        z-index: -2;
    }
    .overlay {
        background-color: #000;
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        bottom 0;
        right: 0;
        z-index: -1;
    }
`


// <!-- Container element -->

const ParallaxElement = () => (
    <Styles>
        <Container fluid className="plax" id="_test" >
            <Row style={{ color: 'lightgrey', textAlign: 'center', padding: '30px', paddingTop: '10rem' }}>
                <Col>
                    <div className="overlay"></div>
                    <h2>О компании</h2>
                    <p>
                        Наша компания специализируется на установке вентиляционных систем и предоставляет услуги любой сложности. Мы работаем с коммерческими, промышленными и жилыми объектами, обеспечивая качественное и эффективное функционирование системы вентиляции.
                        Наша команда состоит из опытных специалистов, которые имеют необходимые знания и опыт работы в данной области. Мы готовы выполнить работы любой сложности, от установки простых вытяжных систем до монтажа сложных инженерных решений.
                        Мы понимаем, что время очень важно для наших клиентов, поэтому мы гарантируем изготовление системы вентиляции в кратчайшие сроки, без ущерба для качества работ. Мы также стремимся обеспечить максимальный уровень удобства для наших клиентов, предоставляя свои услуги в удобное для них время.
                        Мы используем только качественные комплектующие и оборудование, чтобы обеспечить долговечность и надежность системы вентиляции. Мы также гарантируем качество наших работ, предоставляя гарантийное обслуживание и техническую поддержку наших клиентов.
                        Мы стремимся к тому, чтобы наши клиенты были довольны нашей работой и обращались к нам снова и снова, когда им понадобится установка вентиляции. Если вам нужна помощь в этой области, обратитесь к нам, и мы будем рады помочь вам!

                    </p>
                </Col>
            </Row>
        </Container>
    </Styles>
)

//export default ParallaxElement;

function ParallaxTest() {
    return (
        <ParallaxProvider scrollAxis="horizontal">
            <div className="App">
                <ParallaxBanner
                    className="your-class"
                    layers={[
                        {
                            image: ocean,
                            amount: 0.2,
                        },
                        {
                            children: <h1>Some Title</h1>,
                            amount: 0.6,
                        },
                    ]}
                    style={{
                        height: '500px',
                    }}
                >
                </ParallaxBanner>
            </div>
        </ParallaxProvider>
    );
}

//export default ParallaxTest;

const ParallaxContainer = () => (
    <Parallax
        blur={{ min: 4, max: 10 }}
        bgImage={require('../Pipes.jpg')}
        bgImageAlt={ocean}
        strength={-600}
    >
        <Container fluid id="_about" style={{ height: '70vh' }}>
            <Row style={{ color: 'lightgrey', textAlign: 'center', padding: '2rem', paddingTop: '35vmin' }}>
                <Col className="bg-secondary text-warning rounded" md={{ span: 6, offset: 3 }}>
                    <h2>О компании</h2>
                    <p >
                        Производственная компания ПК «ПРОММОНТАЖ-ПВ» специализируется на системах отопления, водоснабжения, канализации.
                        Мы обладаем собственным производством изготовления воздуховодов и изделий вентиляционных систем,
                        наши мощности позволяют производить воздуховоды и фасонные части, водосточные системы слива, желоба, зонты и т.д. из оцинкованной стали и черного металла.
                    </p>
                </Col>
            </Row>
        </Container>
    </Parallax>
);

export default ParallaxContainer;

// 100vh
// height: '500px'
// border-3 rounded border-success
// Наша компания специализируется на установке вентиляционных систем и предоставляет услуги любой сложности. Мы работаем с коммерческими, промышленными и жилыми объектами, обеспечивая качественное и эффективное функционирование системы вентиляции.
//                         Наша команда состоит из опытных специалистов, которые имеют необходимые знания и опыт работы в данной области. Мы готовы выполнить работы любой сложности, от установки простых вытяжных систем до монтажа сложных инженерных решений.
//                         Мы понимаем, что время очень важно для наших клиентов, поэтому мы гарантируем изготовление системы вентиляции в кратчайшие сроки, без ущерба для качества работ. Мы также стремимся обеспечить максимальный уровень удобства для наших клиентов, предоставляя свои услуги в удобное для них время.
//                         Мы используем только качественные комплектующие и оборудование, чтобы обеспечить долговечность и надежность системы вентиляции. Мы также гарантируем качество наших работ, предоставляя гарантийное обслуживание и техническую поддержку наших клиентов.
//                         Мы стремимся к тому, чтобы наши клиенты были довольны нашей работой и обращались к нам снова и снова, когда им понадобится установка вентиляции. Если вам нужна помощь в этой области, обратитесь к нам, и мы будем рады помочь вам!

// Мы работаем с коммерческими, промышленными и жилыми объектами, обеспечивая качественное и эффективное функционирование системы вентиляции.
// Наша команда состоит из опытных специалистов, которые имеют необходимые знания и опыт работы в данной области.