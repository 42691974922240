import React, { useState, useEffect } from "react";
import { Nav, Navbar, Button, Container, Modal, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import logo from '../Pipe1.png';

const Styles = styled.div`
  a, .navbar-brand, .navbar-nav .nav-link {
    color:#9c9c9c;
    &:hover {
        color: white
    }
  }
`;
// #abd1b8
export default function Navibar() {

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    //---------------------
    // const [top, setTop] = useState(true);

    // useEffect(() => {
    //     const scrollHandler = () => {
    //         window.pageYOffset > 10 ? setTop(false) : setTop(true)
    //     };
    //     window.addEventListener('scroll', scrollHandler);
    //     return () => window.removeEventListener('scroll', scrollHandler);
    // }, [top]);
    // styles className={`fixed-top w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`} 
    //---------------------

    return (
        <>
            <Styles className="fixed-top">
                <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand>
                            <img
                                src={logo}
                                height="30"
                                width="30"
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                            &nbsp;<HashLink style={{ textDecoration: 'none' }} to="/#top">ПК «ПРОММОНТАЖ-ПВ»</HashLink>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link><HashLink smooth to="/#top">Главная</HashLink></Nav.Link>
                                <Nav.Link><HashLink smooth to="/#_about">О компании</HashLink></Nav.Link>
                                <Nav.Link><HashLink smooth to="/#_contacts">Контакты</HashLink></Nav.Link>
                            </Nav>
                            {/* <Nav>
                            <Button variant="primary" className="me-2" onClick={handleShow}>Log in</Button>
                            <Button variant="primary" onClick={handleShow}>Sign out</Button>
                        </Nav> */}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Styles>
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Log in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fromBasicEmail">
                            <Form.Label>Email Adress</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">We'll naver share your email with anyone else</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="fromBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter password" />
                        </Form.Group>
                        <Form.Group controlId="fromBasicCheckbox">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

// className="fixed-bottom"

// "/about"
// <Nav.Link><Link to="/about">О компании</Link></Nav.Link>
// "/#_test"
// <Nav.Link><HashLink smooth to="/#_test">Контакты</HashLink></Nav.Link>
// _contacts


// navbar   bg="dark" variant="dark"